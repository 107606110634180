@font-face {
	font-family: "pz-icon";
	src: url("./pz-icon.eot?ba8f0ce6c43386bbfa751a187fdb20c2#iefix") format("embedded-opentype"),
url("./pz-icon.woff2?ba8f0ce6c43386bbfa751a187fdb20c2") format("woff2"),
url("./pz-icon.woff?ba8f0ce6c43386bbfa751a187fdb20c2") format("woff"),
url("./pz-icon.ttf?ba8f0ce6c43386bbfa751a187fdb20c2") format("truetype"),
url("./pz-icon.svg?ba8f0ce6c43386bbfa751a187fdb20c2#pz-icon") format("svg");
}

[class^="pz-icon-"],
[class*=" pz-icon-"],
.pz-icon-font {
	font-family: 'pz-icon' !important;
	font-style: normal;
	font-weight: normal !important;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pz-icon-360:before {
  content: "\f101";
}
.pz-icon-add:before {
  content: "\f102";
}
.pz-icon-adjust-vertical:before {
  content: "\f103";
}
.pz-icon-akinon:before {
  content: "\f104";
}
.pz-icon-arrow-down:before {
  content: "\f105";
}
.pz-icon-arrow-left:before {
  content: "\f106";
}
.pz-icon-arrow-right:before {
  content: "\f107";
}
.pz-icon-arrow-up:before {
  content: "\f108";
}
.pz-icon-bag:before {
  content: "\f109";
}
.pz-icon-bell:before {
  content: "\f10a";
}
.pz-icon-bin:before {
  content: "\f10b";
}
.pz-icon-bleach:before {
  content: "\f10c";
}
.pz-icon-box:before {
  content: "\f10d";
}
.pz-icon-bulb:before {
  content: "\f10e";
}
.pz-icon-bullet-list:before {
  content: "\f10f";
}
.pz-icon-calender:before {
  content: "\f110";
}
.pz-icon-cant-bleach:before {
  content: "\f111";
}
.pz-icon-cart:before {
  content: "\f112";
}
.pz-icon-category:before {
  content: "\f113";
}
.pz-icon-check:before {
  content: "\f114";
}
.pz-icon-chevron-down:before {
  content: "\f115";
}
.pz-icon-chevron-end:before {
  content: "\f116";
}
.pz-icon-chevron-start:before {
  content: "\f117";
}
.pz-icon-chevron-up:before {
  content: "\f118";
}
.pz-icon-clock:before {
  content: "\f119";
}
.pz-icon-clockwise:before {
  content: "\f11a";
}
.pz-icon-close-full:before {
  content: "\f11b";
}
.pz-icon-close:before {
  content: "\f11c";
}
.pz-icon-copy:before {
  content: "\f11d";
}
.pz-icon-credit-card:before {
  content: "\f11e";
}
.pz-icon-cvc:before {
  content: "\f11f";
}
.pz-icon-default:before {
  content: "\f120";
}
.pz-icon-delivery-return:before {
  content: "\f121";
}
.pz-icon-delivery-truck:before {
  content: "\f122";
}
.pz-icon-desklamp:before {
  content: "\f123";
}
.pz-icon-directions:before {
  content: "\f124";
}
.pz-icon-discount:before {
  content: "\f125";
}
.pz-icon-down-small-full:before {
  content: "\f126";
}
.pz-icon-down:before {
  content: "\f127";
}
.pz-icon-edit:before {
  content: "\f128";
}
.pz-icon-envelope:before {
  content: "\f129";
}
.pz-icon-eye-closed:before {
  content: "\f12a";
}
.pz-icon-eye-off:before {
  content: "\f12b";
}
.pz-icon-eye-on:before {
  content: "\f12c";
}
.pz-icon-eye:before {
  content: "\f12d";
}
.pz-icon-facebook-login:before {
  content: "\f12e";
}
.pz-icon-facebook:before {
  content: "\f12f";
}
.pz-icon-gift:before {
  content: "\f130";
}
.pz-icon-giftbox:before {
  content: "\f131";
}
.pz-icon-globe-2:before {
  content: "\f132";
}
.pz-icon-globe:before {
  content: "\f133";
}
.pz-icon-google-login:before {
  content: "\f134";
}
.pz-icon-google:before {
  content: "\f135";
}
.pz-icon-hamburger:before {
  content: "\f136";
}
.pz-icon-hand-washing:before {
  content: "\f137";
}
.pz-icon-handmade:before {
  content: "\f138";
}
.pz-icon-heart-full:before {
  content: "\f139";
}
.pz-icon-heart-stroke:before {
  content: "\f13a";
}
.pz-icon-home:before {
  content: "\f13b";
}
.pz-icon-icon60:before {
  content: "\f13c";
}
.pz-icon-info-circle:before {
  content: "\f13d";
}
.pz-icon-info:before {
  content: "\f13e";
}
.pz-icon-instagram:before {
  content: "\f13f";
}
.pz-icon-layout-2:before {
  content: "\f140";
}
.pz-icon-layout-3:before {
  content: "\f141";
}
.pz-icon-left:before {
  content: "\f142";
}
.pz-icon-linkedin:before {
  content: "\f143";
}
.pz-icon-load:before {
  content: "\f144";
}
.pz-icon-location:before {
  content: "\f145";
}
.pz-icon-lock:before {
  content: "\f146";
}
.pz-icon-logo:before {
  content: "\f147";
}
.pz-icon-mail:before {
  content: "\f148";
}
.pz-icon-menu:before {
  content: "\f149";
}
.pz-icon-message:before {
  content: "\f14a";
}
.pz-icon-minus:before {
  content: "\f14b";
}
.pz-icon-money:before {
  content: "\f14c";
}
.pz-icon-mood-smile:before {
  content: "\f14d";
}
.pz-icon-mp-otp:before {
  content: "\f14e";
}
.pz-icon-no-bleach:before {
  content: "\f14f";
}
.pz-icon-pen:before {
  content: "\f150";
}
.pz-icon-phone:before {
  content: "\f151";
}
.pz-icon-pin:before {
  content: "\f152";
}
.pz-icon-pinterest:before {
  content: "\f153";
}
.pz-icon-plant:before {
  content: "\f154";
}
.pz-icon-play:before {
  content: "\f155";
}
.pz-icon-plus:before {
  content: "\f156";
}
.pz-icon-product:before {
  content: "\f157";
}
.pz-icon-right:before {
  content: "\f158";
}
.pz-icon-ruby:before {
  content: "\f159";
}
.pz-icon-scribble:before {
  content: "\f15a";
}
.pz-icon-search:before {
  content: "\f15b";
}
.pz-icon-share:before {
  content: "\f15c";
}
.pz-icon-spinner:before {
  content: "\f15d";
}
.pz-icon-star-full:before {
  content: "\f15e";
}
.pz-icon-star:before {
  content: "\f15f";
}
.pz-icon-store-pin-selected:before {
  content: "\f160";
}
.pz-icon-store-pin:before {
  content: "\f161";
}
.pz-icon-store:before {
  content: "\f162";
}
.pz-icon-stroke:before {
  content: "\f163";
}
.pz-icon-sugar:before {
  content: "\f164";
}
.pz-icon-tag:before {
  content: "\f165";
}
.pz-icon-tick:before {
  content: "\f166";
}
.pz-icon-track-order:before {
  content: "\f167";
}
.pz-icon-twitter-new:before {
  content: "\f168";
}
.pz-icon-twitter:before {
  content: "\f169";
}
.pz-icon-up:before {
  content: "\f16a";
}
.pz-icon-user:before {
  content: "\f16b";
}
.pz-icon-wand:before {
  content: "\f16c";
}
.pz-icon-washing:before {
  content: "\f16d";
}
.pz-icon-whatsapp:before {
  content: "\f16e";
}
.pz-icon-window:before {
  content: "\f16f";
}
.pz-icon-x-small:before {
  content: "\f170";
}
.pz-icon-youtube:before {
  content: "\f171";
}
