@use '@akinon/next/assets/styles/'as pz-default;
@use './fonts/pz-icon.css' as icons;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .header-grid-template-areas {
    grid-template-areas:
      'band-l band-m band-r'
      'main-l main-m main-r'
      'nav nav nav';
  }

  .header-grid-area-band-l {
    grid-area: band-l;
  }

  .header-grid-area-band-m {
    grid-area: band-m;
  }

  .header-grid-area-band-r {
    grid-area: band-r;
  }

  .header-grid-area-main-l {
    grid-area: main-l;
  }

  .header-grid-area-main-m {
    grid-area: main-m;
  }

  .header-grid-area-main-r {
    grid-area: main-r;
  }

  .header-grid-area-nav {
    grid-area: nav;
  }

  .header-m-template-cols {
    grid-template-columns: auto 1fr 1fr;
  }

  html:has(.scroll-lock) {
    overflow-y: hidden;
    body {
      overflow-y: hidden;
    }
  }

  /* center helper classes */
  .absolute-center-xy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .absolute-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .absolute-center-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .autofill-custom-style.-floating-label:-internal-autofill-previewed,
  .autofill-custom-style.-floating-label:-internal-autofill-selected {
    + label {
      @apply -translate-y-full scale-75 text-primary;
    }
  }
}

body {
  @apply text-primary;
}

/* Config Swiper */
:root {
  --swiper-theme-color: #4d4d4d !important;
}

.invisible-overflow::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}

@media screen and (max-width: 1024px) {
  .mobile-overflow-hidden {
    overflow: hidden !important;
  }
}
body {
  @apply text-primary;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    padding: 2px;
    border: 4px solid #f7f9fa;
    background-color: #e6ecef;
  }
}

.remove-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
html[lang='en'] :is(#chat-box-icon, #chat-box-container) {
  display: none !important;
}

.kvkk-subtitle {
  font-size: 1.75rem;
  font-weight: 500;
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .kvkk-subtitle {
    font-size: 1.25rem;
  }
}

.flatpage-table-wrapper {
  overflow-x: auto;
}

.flatpage-table {
  border: 1px solid #4d4d4d;
  overflow-x: scroll;
}

.flatpage-table tr {
  border-bottom: 1px solid #4d4d4d;
}

.flatpage-table td,
.flatpage-table th {
  padding: 0.5rem;
  border-right: 1px solid #4d4d4d;
}

@media screen and (min-width: 1024px) {
  .swiper-pagination-bullets:has(> .home-bullets) {
    width: 20px !important;
    left: unset !important;
  }  
}

.four-banner-after::after {
  content: '';
  background-color: var(--after-bg-color);
}
